(function() {
  
  $(function() {
    var numberOfSlides = [];
    var $carousel = [];
    $('.owl-carousel').each( function() {
      $carousel = $(this),
      numberOfSlides = $('.item', $carousel).length;
      console.log(numberOfSlides);
       var owlDefaults = {
               loop: false,
               lazyLoad: false,
               margin: 0,
                nav: false,
                navText: [" "," "],
                rewindNav: true,
                items: 1,
                autoplay:true,
                autoplayTimeout:4000,
                autoplayHoverPause:true,
                dots: false
            };

       if (numberOfSlides > 1) {
                   owlDefaults.loop = true;
                   owlDefaults.nav = true;
              }
      $carousel.owlCarousel(owlDefaults);
    });
    

  });
  
  $('.read-more').click(function() {
    $('.desc').toggleClass('active');
  });

})();

